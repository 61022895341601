import React from "react";
import { Footer as Wrapper, ContactInfo } from "@dodobardo/dodolib";
import { Location } from "@styled-icons/evil/Location";
import { Email } from "@styled-icons/material-outlined/Email";
import { PhoneOutline } from "@styled-icons/evaicons-outline/PhoneOutline";
import { Fax } from "@styled-icons/fa-solid/Fax";
import { Instagram } from "@styled-icons/boxicons-logos/Instagram";
import { Linkedin } from "@styled-icons/boxicons-logos/Linkedin";
import { Calendar } from "@styled-icons/ionicons-outline/Calendar";
import { useTranslation } from "react-i18next";
import LanguageSelector from "./LanguageSelector";

const Footer = () => {
  const { t } = useTranslation();

  return (
    <Wrapper lg primary t={t}>
      <ContactInfo
        link={`http://maps.google.com/?q=${t("company.address")}`}
        Img={Location}
        primary
        text={t("company.address")}
      />
      <ContactInfo
        link={`mailto:${t("company.email")}`}
        Img={Email}
        primary
        text={t("company.email")}
      />
      <ContactInfo
        link={`tel:${t("company.phone_number")}`}
        primary
        Img={PhoneOutline}
        text={t("company.phone_number")}
      />
      <ContactInfo
        link={`tel:${t("company.phone_number")}`}
        primary
        Img={Fax}
        text={t("company.fax")}
      />
      <ContactInfo
        link={`https://www.instagram.com/roda_srl/`}
        primary
        Img={Instagram}
        text={t("company.instagram")}
      />
      <ContactInfo
        link={`https://www.linkedin.com/in/officina-meccanica-roda-s-r-l-a56990131`}
        primary
        Img={Linkedin}
        text={t("company.linkedin")}
      />
      <ContactInfo primary Img={Calendar} text={t("company.times")} />
      <LanguageSelector>ITALIANO</LanguageSelector>
    </Wrapper>
  );
};

export default Footer;
